<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div>
        <b-badge
          pill
          role="button"
          class="pt-50 pr-75 pb-50 pl-75"
          :variant="$route.name === 'orders-local' ? 'primary' : 'light-primary'"
          @click="onClickBadge('orders-local')"
        >
          {{ $t('_orderType.Dine in') }}
        </b-badge>
      </div>
      <div>
        <b-badge
          pill
          role="button"
          class="pt-50 pr-75 pb-50 pl-75"
          :variant="$route.name === 'orders-history' ? 'primary' : 'light-primary'"
          @click="onClickBadge('orders-history')"
        >
          {{ $t('orderHistoryPageName') }}
        </b-badge>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'Orders',
  components: {
    BBadge,
  },
  methods: {
    onClickBadge(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName })
      }
    },
  },
}
</script>
